<template>
    <b-container fluid>
        <iq-card>
           <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('tpm_report.officer_material_report') }} {{ $t('globalTrans.search') }}</h4>
            </template>
            <template v-slot:body>
              <b-row>
                <b-col lg="12" sm="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
                      <b-row>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Circular Memo No" vid="circular_memo_no" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="circular_memo_no"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_iabm.circular_memo_no')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="search.circular_memo_no"
                                  :options="circularList"
                                  id="circular_memo_no"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                            <ValidationProvider name="Payment Type" vid="payment_type" rules="required">
                                <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="payment_type"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{$t('elearning_iabm.registration_for')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                    plain
                                    v-model="search.payment_type"
                                    :options="paymentTypeList"
                                    id="payment_type"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <!-- <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template> -->
                                    </b-form-select>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                      </b-row>
                        <b-overlay :show="loadingSearch">
                            <b-row>
                                <b-col lg="6" sm="6">
                                    <ValidationProvider name="Training Type"  vid="training_type_id" rules="required|min_value:1">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="training_type_id"
                                        slot-scope="{ valid, errors }"
                                    >
                                        <template v-slot:label>
                                        {{$t('elearning_config.training_type')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                        plain
                                        v-model="search.training_type_id"
                                        :options="trainingTypeList"
                                        id="training_type_id"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        disabled
                                        >
                                        <template v-slot:first>
                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="6">
                                    <ValidationProvider name="Training Category" vid="training_category_id" rules="required|min_value:1">
                                        <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="training_category_id"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                            {{$t('elearning_config.training_category')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                            plain
                                            v-model="search.training_category_id"
                                            :options="trainingCategoryList"
                                            id="training_category_id"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            disabled
                                            >
                                            <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                            </b-form-select>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="6">
                                    <ValidationProvider name="Training Title" vid="training_title_id" rules="required|min_value:1">
                                        <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="training_title_id"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                            {{$t('elearning_config.training_title')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                            plain
                                            v-model="search.training_title_id"
                                            :options="trainingTitleList"
                                            id="training_title_id"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            disabled
                                            >
                                            <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                            </b-form-select>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>
                        </b-overlay>
                      <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ $t('globalTrans.search') }}</b-button>
                          &nbsp;
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </template>
        </iq-card>
        <b-row>
          <b-col md="12" v-if="showData">
            <b-overlay :show="loading">
              <b-row v-if='datas.length > 0'>
                <b-col md="12">
                  <iq-card>
                    <template v-slot:headerTitle>
                      <h4 class="card-title">{{ $t('tpm_report.officer_material_report') }}</h4>
                    </template>
                    <template v-slot:headerAction>
                      <b-button class="btn_add_new" @click="pdfExport">
                        <i class="fas fa-print"></i>{{ $t('globalTrans.print') }}
                      </b-button>
                      <!-- <export-excel
                        class="btn btn-success mr-2"
                        :data="excelData"
                        :title="headerValue"
                        worksheet="Report Sheet"
                        name="payment_report.xls">
                        {{ $t('globalTrans.export_excel') }}
                      </export-excel> -->
                    </template>
                    <template v-slot:body>
                      <b-overlay>
                        <b-row mt-5>
                          <b-col md="12" class="table-responsive">
                            <div style="border: 2px solid;margin:10px;padding:10px">
                              <b-row>
                                <b-col>
                                  <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="12">
                                    <template v-slot:projectNameSlot>
                                      {{ }}
                                    </template>
                                    {{ $t('tpm_report.officer_material_report') }}
                                  </list-report-head>
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col>
                                  <div class="text-center">
                                    <table style="width:100%;color:black;">
                                      <tr v-if="search.date">
                                        <td align="right" style="width:45%">{{ $t('globalTrans.date') }}</td>
                                        <td align="center" style="width:5%">:</td>
                                        <td align="left" style="width:50%">{{ search.date | dateFormat }}</td>
                                      </tr>
                                      <tr v-if="search.fiscal_year">
                                        <td align="right" style="width:45%">{{ $t('elearning_config.fiscal_year') }}</td>
                                        <td align="center" style="width:5%">:</td>
                                        <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.fiscal_year_bn : search.fiscal_year }}</td>
                                      </tr>
                                      <tr v-if="search.payment_type">
                                        <td align="right" style="width:45%">{{ $t('elearning_iabm.registration_for') }}</td>
                                        <td align="center" style="width:5%">:</td>
                                        <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.payment_type_bn : search.payment_type }}</td>
                                      </tr>
                                      <tr v-if="search.training_type">
                                        <td align="right" style="width:45%">{{ $t('elearning_config.training_type') }}</td>
                                        <td align="center" style="width:5%">:</td>
                                        <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.training_type_bn : search.training_type }}</td>
                                      </tr>
                                      <tr v-if="search.training_category">
                                        <td align="right" style="width:45%">{{ $t('elearning_config.training_category') }}</td>
                                        <td align="center" style="width:5%">:</td>
                                        <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.training_category_bn : search.training_category }}</td>
                                      </tr>
                                      <tr v-if="search.training_title">
                                        <td align="right" style="width:45%">{{ $t('elearning_config.training_title') }}</td>
                                        <td align="center" style="width:5%">:</td>
                                        <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.training_title_bn : search.training_title }}</td>
                                      </tr>
                                    </table>
                                  </div>
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col>
                                  <b-table-simple class="tg mt-3" bordered hover small caption-top responsive v-if="datas.length > 0">
                                    <b-thead>
                                      <b-tr>
                                        <b-th style="width:7%; vertical-align: middle" class="text-center" rowspan="2">{{$t('globalTrans.sl_no')}}</b-th>
                                        <b-th style="width:14%; vertical-align: middle" class="text-center" rowspan="2">{{$t('globalTrans.name')}}</b-th>
                                        <b-th style="width:12%; vertical-align: middle" class="text-center" rowspan="2">{{$t('tpm_report.designation')}}</b-th>
                                        <b-th class="text-center" :colspan="materials.length">{{$t('elearning_tim.materials')}}</b-th>
                                      </b-tr>
                                      <b-tr>
                                      <b-th v-for="(material, index1) in materials" :key="index1" style="vertical-align: middle" class="text-center">
                                            {{ $i18n.locale === 'bn' ? material.name_bn : material.name }}
                                        </b-th>
                                       </b-tr>
                                    </b-thead>
                                    <b-tbody v-for="(info, index) in datas" :key="index">
                                      <b-td class="text-center">{{ $n(index + 1, { useGrouping: false }) }}</b-td>
                                      <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.personal.name_bn : info.personal.name }}</b-td>
                                      <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.designation_name_bn : info.designation_name }}</b-td>
                                      <b-td class="text-center" v-for="(material, index1) in materials" :key="index1">
                                        {{ $n(material.quantity) }}
                                      </b-td>
                                    </b-tbody>
                                  </b-table-simple>
                                </b-col>
                              </b-row>
                            </div>
                          </b-col>
                        </b-row>
                      </b-overlay>
                    </template>
                  </iq-card>
                </b-col>
              </b-row>
              <div class="panel-body text-center mt-3" v-else>
                <h6 class="text-danger">{{ $t('globalTrans.noDataFound') }}</h6>
              </div>
            </b-overlay>
          </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { billPaymentReport, circularPublicationList, circularPublication } from '../../api/routes'
import flatpickr from 'flatpickr'
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'
import ExportPdf from './export_pdf_details'
import excel from 'vue-excel-export'
import Vue from 'vue'
Vue.use(excel)

export default {
    props: ['id'],
    components: {
        ValidationObserver,
        ValidationProvider,
        ListReportHead
    },
    created () {
        this.getCircularList()
        // if (this.id) {
        //     const tmp = this.getEditingData()
        //     this.search = tmp
        // }
    },
    data () {
        return {
          saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
          search: {
            circular_memo_no: 0,
            training_type_id: 0,
            training_category_id: 0,
            payment_type: 'Trainee',
            material: 1,
            // payment_status: 2,
            training_title_id: 0
          },
          headerExcelDefault: {
            orgName: null,
            orgNameBn: null,
            address: null,
            address_bn: null
          },
          trainingCategoryList: [],
          trainingTitleList: [],
          trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
          datas: [],
          materials: [],
          circularList: [],
          loading: false,
          loadingSearch: false,
          showData: false
        }
    },
    mounted () {
        core.index()
        flatpickr('.fromDate', {})
        this.AveragePriceText = (parseInt(this.search.HighestPrice) + parseInt(this.search.LowestPrice)) / 2
    },
    computed: {
      headerValue: function () {
        const headerVal = []
        if (this.$i18n.locale === 'en') {
          headerVal[0] = this.headerExcelDefault.orgName
          headerVal[1] = this.headerExcelDefault.address
          headerVal[2] = this.$t('tpm_report.officer_material_report')
        } else {
          headerVal[0] = this.headerExcelDefault.orgNameBn
          headerVal[1] = this.headerExcelDefault.address_bn
          headerVal[2] = this.$t('tpm_report.officer_material_report')
        }
        if (this.search.date) {
          headerVal.push(
            this.$t('globalTrans.date') + ' :  ' + this.search.date
          )
        }
        if (this.search.fiscal_year) {
          headerVal.push(
            (this.$i18n.locale === 'bn') ? this.$t('globalTrans.fiscal_year') + ' :  ' + this.search.fiscal_year_bn : this.$t('globalTrans.fiscal_year') + ' :  ' + this.search.fiscal_year
          )
        }
        if (this.search.org_name) {
          headerVal.push(
            (this.$i18n.locale === 'bn') ? this.$t('elearning_config.organization') + ' :  ' + this.search.org_name_bn : this.$t('elearning_config.organization') + ' :  ' + this.search.org_name
          )
        }
        if (this.search.training_type) {
          headerVal.push(
            (this.$i18n.locale === 'bn') ? this.$t('elearning_config.training_type') + ' :  ' + this.search.training_type_bn : this.$t('elearning_config.training_type') + ' :  ' + this.search.training_type
          )
        }
        if (this.search.training_category) {
          headerVal.push(
            (this.$i18n.locale === 'bn') ? this.$t('elearning_config.training_category') + ' :  ' + this.search.training_category_bn : this.$t('elearning_config.training_category') + ' :  ' + this.search.training_category
          )
        }
        if (this.search.training_title) {
          headerVal.push(
            (this.$i18n.locale === 'bn') ? this.$t('elearning_config.training_title') + ' :  ' + this.search.training_title : this.$t('elearning_config.training_title') + ' :  ' + this.search.training_title
          )
        }
        return headerVal
      },
      excelData: function () {
        const listData = this.datas
        var serial = 0
        const listContractor = listData.map(keyItem => {
          serial++
            if (this.$i18n.locale === 'en') {
                return {
                  sl: serial,
                  Type: keyItem.payment_type,
                  Name: keyItem.personal.name,
                  'Honour Amount': keyItem.honour_amount,
                  'Vat/Tax (%)': keyItem.vat_tax,
                  Other: keyItem.other,
                  Detucton: keyItem.detucton,
                  'Payment Amount': keyItem.payment_amount
                }
            } else {
              return {
                'ক্রমিক সংখ্যা': this.$n(serial),
                টাইপ: keyItem.payment_type,
                নাম: keyItem.personal.name,
                'সম্মানের পরিমাণ': this.$n(keyItem.honour_amount),
                'মুল্য সংযোজন কর (%)': this.$n(keyItem.vat_tax),
                অন্যান্য: this.$n(keyItem.other),
                বিচ্ছিন্ন: this.$n(keyItem.detucton),
                'পরিশোধিত অর্থ': this.$n(keyItem.payment_amount)
              }
            }
        })
        return listContractor
      },
      trainingTypeList: function () {
        return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
      },
      paymentTypeList: function () {
        return this.$store.state.TrainingElearning.commonObj.paymentType
      },
      fiscalYearList: function () {
        return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      },
      orgList: function () {
        return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
      },
      trainerEvaluationList: function () {
        return this.$store.state.TrainingElearning.commonObj.trainerEvaluationList.filter(item => item.status === 1)
      },
      timePreiodList: function () {
        return this.$store.state.agriMarketing.commonObj.timePreiodList
      },
      i18 () {
        return this.$i18n.locale
      }
    },
    watch: {
      'search.circular_memo_no': function (newValue) {
            this.getCircularMemoNo(newValue)
        },
      'search.training_type_id': function (newValue) {
        this.trainingCategoryList = this.getCategoryList(newValue)
      },
      'search.training_category_id': function (newValue) {
        this.trainingTitleList = this.getTrainingTitleList(newValue)
      }
    },
    methods: {
        async getCircularList () {
            this.circularLoading = true
            const serchData = {
                org_id: this.$store.state.dataFilters.orgId
            }
            const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularPublicationList + '/' + 'tpm_training_calendar' + '/' + 1, serchData)
            if (!result.success) {
            } else {
                const listObject = result.data
                const tmpList = listObject.map((obj, index) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.circular_memo_no, text: obj.circular_memo_no }
                } else {
                    return { value: obj.circular_memo_no, text: obj.circular_memo_no }
                }
                })
                this.circularList = tmpList
            }
            this.circularLoading = false
        },
        async getCircularMemoNo (getCircularMemoNo) {
            if (getCircularMemoNo) {
                this.loading = true
                this.loadingSearch = true
                const params = {
                  circular_memo_no: getCircularMemoNo,
                  table: 'tpm_training_calendar'
                }
                const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularPublication, params)
                if (!result.success) {
                } else {
                    this.circularErrorMsg = false
                    const trData = result.data
                    this.search.training_title_id = trData.training_title_id
                    this.search.training_type_id = trData.training_type_id
                    this.search.training_category_id = trData.training_category_id
                }
                this.loading = false
                this.loadingSearch = false
            }
        },
      getCategoryList (typeId) {
        const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
        if (typeId) {
          return trainingCategoryList.filter(item => item.training_type_id === typeId)
        }
        return trainingCategoryList
      },
      getTrainingTitleList (categoryId) {
        const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
        if (categoryId) {
          return trainingTitleList.filter(item => item.training_category_id === categoryId)
        }
        return trainingTitleList
      },
      pdfExport () {
          const reportTitle = this.$t('tpm_report.officer_material_report')
          ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this.datas, this, this.search, this.materials)
      },
      onChangeFile (e) {
          this.attachmentDemo = e.target.files[0]
      },
      getEditingData () {
          const tmpData = this.$store.state.list.find(item => item.id === this.id)
          return JSON.parse(JSON.stringify(tmpData))
      },
      async searchData () {
        RestApi.getData(trainingElearningServiceBaseUrl, '/config/report-head/detail/12').then(response => {
          if (response.success) {
            const orgList = this.$store.state.commonObj.organizationProfileList.find(item => item.value === 3)
            const orgName = typeof orgList !== 'undefined' ? orgList.text_en : ''
            const orgNameBn = typeof orgList !== 'undefined' ? orgList.text_bn : ''
            this.headerExcelDefault.orgName = orgName
            this.headerExcelDefault.orgNameBn = orgNameBn
            this.headerExcelDefault.address = response.data.address
            this.headerExcelDefault.address_bn = response.data.address_bn
          }
        })
        this.loading = true
        this.showData = true
        const orgObj = this.$store.state.commonObj.organizationProfileList.find(item => item.value === this.search.org_id)
        this.search.org_name = typeof orgObj !== 'undefined' ? orgObj.text_en : ''
        this.search.org_name_bn = typeof orgObj !== 'undefined' ? orgObj.text_bn : ''

        const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(item => item.value === this.search.fiscal_year_id)
        this.search.fiscal_year = typeof fiscalYearObj !== 'undefined' ? fiscalYearObj.text_en : ''
        this.search.fiscal_year_bn = typeof fiscalYearObj !== 'undefined' ? fiscalYearObj.text_bn : ''

        const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(item => item.value === this.search.training_type_id)
        this.search.training_type = typeof trainingTypeObj !== 'undefined' ? trainingTypeObj.text_en : ''
        this.search.training_type_bn = typeof trainingTypeObj !== 'undefined' ? trainingTypeObj.text_bn : ''

        const trainingCategoryListObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(item => item.value === this.search.training_category_id)
        this.search.training_category = typeof trainingCategoryListObj !== 'undefined' ? trainingCategoryListObj.text_en : ''
        this.search.training_category_bn = typeof trainingCategoryListObj !== 'undefined' ? trainingCategoryListObj.text_bn : ''

        const trainingTitleListObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(item => item.value === this.search.training_title_id)
        this.search.training_title = typeof trainingTitleListObj !== 'undefined' ? trainingTitleListObj.text_en : ''
        this.search.training_title_bn = typeof trainingTitleListObj !== 'undefined' ? trainingTitleListObj.text_bn : ''

        const paymentTypeObj = this.$store.state.TrainingElearning.commonObj.paymentType.find(item => item.value === this.search.payment_type)
        this.search.payment_type = typeof paymentTypeObj !== 'undefined' ? paymentTypeObj.text_en : ''
        this.search.payment_type_bn = typeof paymentTypeObj !== 'undefined' ? paymentTypeObj.text_bn : ''

        const result = await RestApi.getData(trainingElearningServiceBaseUrl, billPaymentReport, this.search)
        if (result.success) {
          this.loading = false
          if (result.data) {
            this.datas = this.getCustomDataList(result.data)
            this.materials = result.material
          }
        } else {
          this.datas = []
          this.materials = []
          this.loading = false
        }
      },
      getCustomDataList (data) {
        const listData = data.map(item => {
          const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
          const orgData = {}
          if (typeof orgObj !== 'undefined') {
            orgData.org = orgObj.text_en
            orgData.org_bn = orgObj.text_bn
          } else {
            orgData.org = ''
            orgData.org_bn = ''
          }

          const officeObj = this.$store.state.commonObj.officeList.find(doc => doc.value === parseInt(item.office_id))
          const officeData = {}
          if (typeof officeObj !== 'undefined') {
            officeData.office = officeObj.text_en
            officeData.office_bn = officeObj.text_bn
          } else {
            officeData.office = ''
            officeData.office_bn = ''
          }

          const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
          const fiscalYearData = {}
          if (typeof fiscalYearObj !== 'undefined') {
            fiscalYearData.fiscal_year = fiscalYearObj.text_en
            fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
          } else {
            fiscalYearData.fiscal_year = ''
            fiscalYearData.fiscal_year_bn = ''
          }
          const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
          const trainingTitleData = {}
          if (typeof trainingTitleObj !== 'undefined') {
            trainingTitleData.training_title = trainingTitleObj.text_en
            trainingTitleData.training_title_bn = trainingTitleObj.text_bn
          } else {
            trainingTitleData.training_title = ''
            trainingTitleData.training_title_bn = ''
          }
          const OfficeTypeListObj = this.$store.state.commonObj.officeTypeList.find(item1 => item1.value === parseInt(item.office_type_id))
          const OfficeTypeData = {}
          if (typeof OfficeTypeListObj !== 'undefined') {
            OfficeTypeData.office_type = OfficeTypeListObj.text_en
            OfficeTypeData.office_type_bn = OfficeTypeListObj.text_bn
          } else {
            OfficeTypeData.office_type = ''
            OfficeTypeData.office_type_bn = ''
          }
          const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
          const trainingTypeData = {}
          if (typeof trainingTypeObj !== 'undefined') {
            trainingTypeData.training_type = trainingTypeObj.text_en
            trainingTypeData.training_type_bn = trainingTypeObj.text_bn
          } else {
            trainingTypeData.training_type = ''
            trainingTypeData.training_type_bn = ''
          }
          const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
          const trainingCategoryData = {}
          if (typeof trainingCategoryObj !== 'undefined') {
            trainingCategoryData.training_category = trainingCategoryObj.text_en
            trainingCategoryData.training_category_bn = trainingCategoryObj.text_bn
          } else {
            trainingCategoryData.training_category = ''
            trainingCategoryData.training_category_bn = ''
          }
          const desigObj = this.$store.state.commonObj.designationList.find(designation => designation.value === parseInt(item.designation_id))
          const desigData = {}
          if (typeof desigObj !== 'undefined') {
            desigData.designation_name = desigObj.text_en
            desigData.designation_name_bn = desigObj.text_bn
          } else {
            desigData.designation_name = ''
            desigData.designation_name_bn = ''
          }
          return Object.assign({}, item, orgData, officeData, fiscalYearData, trainingTitleData, OfficeTypeData, trainingTypeData, trainingCategoryData, desigData)
        })
        return listData
      }
    }
}
</script>
